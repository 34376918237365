<template>
  <div class="container">
    <div class="txt1">
      <span>委托时间：{{ detail.entrust_time }}</span>
      <span>{{ detail.is_view == 2 ? "已读" : "未读" }}</span>
    </div>
    <div
      class="person_personInfo"
      style="padding-bottom: 0.25rem; border-bottom: 0.02rem solid #eeeeee"
    >
      <div class="person_personInfo">
        <!-- <img class="person_avatar" src="../../assets/user_1.png" alt=""> -->
        <img
          class="person_avatar"
          v-if="detail.gender == '1'"
          src="../../assets/user_1.png"
          alt=""
        />
        <img
          class="person_avatar"
          v-else-if="detail.gender == '2'"
          src="../../assets/user_2.png"
          alt=""
        />
        <img
          class="person_avatar"
          v-else
          src="../../assets/nosexAvatar.jpg"
          alt=""
        />
        <div>
          <div class="person_username">{{ detail.name }}</div>
          <div class="person_userInfo">
            性别： <span v-if="detail.gender == 1">男</span>
            <span v-else-if="detail.gender == 2">女</span
            ><span v-else>保密</span> | 年龄：{{ detail.age || "保密" }}
            <span v-if="detail.age">岁</span>
          </div>
        </div>
      </div>

      <div style="color: #ff7626">
        <div class="username">{{ (detail.final_sim * 10).toFixed(2) }}%</div>
        <div class="userInfo" style="color: #ff7626; text-align: center">
          适配度
        </div>
      </div>
    </div>
    <div class="btn1" @click="handlepop1">
      <img
        class="btn1img"
        style=""
        v-if="detail.referrer_logo == null"
        src="../../assets/floor.png"
        alt=""
      />
      <img class="btn1img" style="" v-else :src="detail.referrer_logo" alt="" />
      行业大咖{{ detail.referrer_nickname }}倾情介绍 >
    </div>
    <div class="iconlist">
      <div style="text-align: center" @click="goReport">
        <div class="item1">
          <img
            style="width: 0.6rem; object-fit: cover"
            src="../../assets/icon_1@2x.png"
            alt=""
          />
        </div>
        <span style="line-height: 0.8rem">能力及适配度报告</span>
      </div>
      <div style="text-align: center">
        <div class="item1">
          <img
            style="width: 0.6rem; object-fit: cover"
            src="../../assets/icon_2@2x.png"
            alt=""
          />
        </div>
        <span style="line-height: 0.8rem">查看背调</span>
      </div>
      <div style="text-align: center" @click="viewResume">
        <div class="item1">
          <img
            style="width: 0.6rem; object-fit: cover"
            src="../../assets/icon_3@2x.png"
            alt=""
          />
        </div>
        <span style="line-height: 0.8rem">查看简历</span>
      </div>
    </div>

    <div class="txt2">候选人掌握技能：</div>

    <div :class="isShow ? 'abilityList' : 'abilityListNormal'">
      <div
        class="abilityItem"
        v-for="(item, index) in skills_tags"
        :key="index"
      >
        {{ item.tag_name }}
      </div>
    </div>

    <div class="viewMore" @click="viewMoreSkill()">
      {{ txt }}
    </div>
    <div class="pushbtn" v-if="detail.is_entrust != 1" @click="handlepop2">
      跟进候选人
    </div>
    <!-- <div class="pushbtn" v-else>
      当前状态：{{ flowStatus(detail.flow_status) }}
    </div> -->
    <div style="height: 1rem"></div>
    <!-- 行业大咖弹框-->
    <van-popup v-model="pop1" round :style="{ width: '70%' }">
      <div class="popcontainer">
        <img
          style="width: 100%"
          v-if="detail.avatar_url == null"
          src="../../assets/1660287920565.png"
          alt=""
        />
        <img style="width: 100%" v-else :src="detail.avatar_url" alt="" />
        <div class="popName">{{ detail.referrer_nickname }}</div>
        <div style="text-align: center">
          {{ detail.user_info ? detail.user_info : "暂无内容" }}
        </div>
      </div>
    </van-popup>

    <!-- 候选人跟进弹框 -->
    <van-popup v-model="pop2" round :style="{ width: '70%' }">
      <div>
        <div class="pop2">
          <img
            style="width: 2.5rem; margin-top: 0.5rem"
            src="../../assets/smail.png"
            alt=""
          />
          <div style="line-height: 1rem">候选人已跟进成功</div>
        </div>
        <div class="pop2btn" @click="pop2 = false">确认</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pop1: false,
      pop2: false,
      isShow: false,
      txt: "展开查看详情",
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    flowStatus(status) {
      let str;
      switch (status) {
        case 1:
          str = "已投递";
          break;
        case 2:
          str = "已委托";
          break;
        case 3:
          str = "已接单";
          break;
        case 4:
          str = "已驳回 ";
          break;
        case 5:
          str = "接单已确定";
          break;
        case 6:
          str = "已撮合";
          break;
        case 7:
          str = "面试反馈";
          break;
        case 8:
          str = "面试反馈";
          break;
        case 9:
          str = "已入职";
          break;
        case 10:
          str = "未入职";
          break;
        case 11:
          str = "已过保";
          break;
        case 12:
          str = "未过保";
          break;
        case 13:
          str = "结算完成";
          break;
      }
      return str;
    },
    viewMoreSkill() {
      this.isShow = !this.isShow;
      if (this.isShow == true) {
        this.txt = "收起";
      } else {
        this.txt = "展开查看详情";
      }
    },
    handlepop1() {
      this.pop1 = true;
    },
    getDetail() {
      console.log(1111111);
      this.$http
        .post("/firm/v1/record/viewPostDetails", {
          reqType: "record",
          base_id: this.$route.query.id,
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          let arr = JSON.parse(res.data.assessment);
          this.skills_tags = arr.skills_tags;
          this.detail = res.data;
        });
    },
    handlepop2() {
      this.$http
        .post("/firm/v1/record/selectedMan", {
          reqType: "record",
          base_id: this.$route.query.id,
          user_id: localStorage.getItem("userId"),
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          if (res.code == 0) {
            this.pop2 = true;
          }
        });
    },
    goReport() {
      this.$router.push({
        path: "/personReport",
        query: {
          id: this.detail.base_id,
        },
      });
    },
    viewResume() {
      this.$router.push({
        path: "/resumeDetails",
        query: {
          id: this.detail.base_id,
        },
      });
    },
  },
};
</script>
<style scoped>
.container {
  background: #fff;
  padding: 0 0.3rem;
}

.btn1 {
  position: relative;
  width: 4.5rem;
  height: 0.8rem;
  background: linear-gradient(86deg, #ffb056 0%, #ff782b 100%);
  border-radius: 0.38rem;
  line-height: 0.8rem;
  color: #fff;
  text-align: center;
  margin: 0.5rem auto;
}
.btn1img {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  left: -0.02rem;
  border-radius: 50%;
  border: 0.05rem solid #fff;
}
.item1 {
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff3e0;
  border-radius: 50%;
  margin: 0 auto;
}

.viewMore {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff7626;
  text-align: center;
  line-height: 0.8rem;
}
.pushbtn {
  margin: 0 auto;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.28rem;
  width: 6rem;
  height: 0.8rem;
  background: #ffffff;
  border-radius: 0.1rem;
  border: 0.01rem solid #ff4822;
  color: #ff7626;
  font-weight: 600;
  margin-top: 0.5rem;
}
.popName {
  line-height: 0.7rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  text-align: center;
}
.popcontainer {
  padding: 0.2rem;
  box-sizing: border-box;
}
.pop2btn {
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff7626;
  text-align: center;
  border-top: 0.01rem solid #eeeeee;
}
.pop2 {
  text-align: center;
  color: #696b6f;
  font-size: 0.3rem;
}
</style>